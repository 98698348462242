import { AppState } from 'common/state-type'
import LogInPage from 'common/components/Authentication/LogInPage'
import useProfile from 'common/providers/useProfile'
import useLawyersProfile from 'common/providers/useLawyersProfile'
import Router from 'next/router'
import { useCallback } from 'react'

const LoginPage = () => {
  const { getLawyersProfile } = useLawyersProfile()
  const { getProfile } = useProfile()

  const onSuccess = useCallback(
    (cognitoUser: AppState['user']) => {
      getProfile({
        onSuccess: () => {
          getLawyersProfile(
            {},
            {
              onSuccess: () => {
                Router.replace('/account')
              },
              onError: () => {
                Router.replace('/mfa')
              },
            },
          )
        },
      })
    },
    [getLawyersProfile, getProfile],
  )

  return <LogInPage onSuccess={onSuccess} />
}

export default LoginPage
